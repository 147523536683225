import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

import {
    Navbar,
    Footer,
    Contact,
    ProjectStack,
    ProjectsCloud,
    projects,
    Header,
    Section,
} from '../components';

export const Designshuffle = () => {
    return (
        <main className="">
            <Helmet>
                <title>DesignShuffle - B. Jasulan</title>
            </Helmet>
            <Navbar />
            <Section>
                <Header align="left">DesignShuffle</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        DesignShuffle is a project I created to make it easier
                        to build landing pages by using the power of randomness
                        and TailwindCSS.
                    </p>
                    <ProjectStack
                        type="Personal Project"
                        stack={[
                            'TypeScript',
                            'React',
                            'Redux',
                            'TailwindCSS',
                            'Webpack',
                            'NX',
                            'JSONSchema',
                        ]}
                        repository="https://git.bjasulan.com/designshuffle/designshuffle"
                        website="https://designshuffle.xyz"
                    />
                </div>
                <div>
                    <StaticImage
                        className="rounded-lg shadow-xl"
                        alt="DesignShuffle"
                        src="../images/page-builder.png"
                    />
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Objectives and Goal</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        Sometimes the main problem with designing landing pages
                        is a blank page that puts you in a state of stupor
                        because you don't know where to start. And that is the
                        primary problem this app solves. It is achieved by
                        placing a random amount of sections on the design canvas
                        and using random colors.
                    </p>
                    <p className="mt-8">
                        I didn't want the app to be too complex. So, I gave
                        myself a timeframe of at most a couple of weekends.
                    </p>
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Technology Stack</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        For design sections I decided to use TailwindCSS because
                        it makes it very easy to prototype new sections and keep
                        the CSS size manageable thanks to its built-in JIT
                        mechanism.
                    </p>
                    <p className="mt-8">
                        As for the UI, I could have implemented the app using
                        either React or Vue. I decided to go with React simply
                        because I like it more. For state management there are a
                        lot of options nowadays. There is Recoil, Mobx, Redux,
                        and many more. I went with Redux because I know it
                        better.
                    </p>
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Problems and Thought Process</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        The biggest challenge I faced was implementing design
                        sections in such a way so that their settings are
                        rendered dynamically without writing a lot of code. I
                        decided to implement settings by using JSONSchema.
                    </p>
                    <p className="mt-8">
                        In other words, each design section implements a schema
                        for settings. Then, the app intelligently picks up that
                        JSON schema and chooses whether to render a text input,
                        radio input, or any other input based on the settings
                        type.
                    </p>
                </div>
                <div className="mt-16">
                    <StaticImage
                        className="rounded-lg shadow-xl"
                        alt="DesignShuffle"
                        src="../images/page-builder-settings.png"
                    />
                </div>
            </Section>
            <Section
                header="Lessons Learned & Future Work"
                className="pt-14 md:pt-16 pb-32 mt-3"
            >
                <div className="mx-auto max-w-4xl text-base leading-8">
                    <p>
                        With this project I refreshed my knowledge of React and
                        Redux. I also learned that JSONSchema can be a very good
                        tool when designing systems where you want to allow
                        users some access to the way information is presented in
                        your UI without sacrificing security.
                    </p>
                    <p className="mt-8">
                        In the future, I could learn even more by expanding on
                        this idea by using machine learning and AST format of
                        HTML to create random design sections as well.
                    </p>
                </div>
            </Section>
            <div className="mb-16">
                <ProjectsCloud active={projects[2]} />
            </div>
            <Contact />
            <Footer />
        </main>
    );
};

export default Designshuffle;
